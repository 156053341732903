const configs = {
  positions: {
    get: {
      type: 'get',
      path: '/positions',
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
    search: {
      get: {
        type: 'get',
        path: '/positions/search',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  places: {
    suggests: {
      get: {
        type: 'get',
        path: '/places/suggests',
        baseURL: process.env.NEXT_PUBLIC_PLACE_API_URL,
      },
    },
    details: {
      get: {
        type: 'get',
        path: '/places/details/addresses',
        baseURL: process.env.NEXT_PUBLIC_PLACE_API_URL,
      },
    },
    geo: {
      get: {
        type: 'get',
        path: '/places/details/geo',
        baseURL: process.env.NEXT_PUBLIC_PLACE_API_URL,
      },
    },
  },
  notificationcenter: {
    notifications: {
      get: {
        type: 'get',
        path: '/notificationcenter/notifications',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
      count: {
        get: {
          type: 'get',
          path: '/notificationcenter/notifications/count',
          isAuth: true,
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
    },
  },
  accountconfirmationnotification: {
    get: {
      type: 'get',
      path: '/AccountConfirmationNotification',
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
  },
  seo: {
    staticPositions: {
      get: {
        type: 'get',
        path: '/positions/static-positions',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    cities: {
      get: {
        type: 'get',
        path: '/seo/cities',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    towns: {
      get: {
        type: 'get',
        path: '/seo/towns',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    positions: {
      get: {
        type: 'get',
        path: '/seo/positions',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    sectors: {
      get: {
        type: 'get',
        path: '/seo/sectors',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  webseourl: {
    get: {
      type: 'get',
      path: '/settings/web/seourls',
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
  },
  seourl: {
    get: {
      type: 'get',
      path: '/seo-urls',
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
  },
  salary: {
    calculate: {
      months: {
        get: {
          type: 'get',
          path: '/salary/calculate/months',
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
      years: {
        get: {
          type: 'get',
          path: '/salary/calculate/years',
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
    },
    maritalstatus: {
      get: {
        type: 'get',
        path: '/salary/maritalstatus',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    process: {
      types: {
        get: {
          type: 'get',
          path: '/salary/process/types',
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
    },
    calculation: {
      get: {
        type: 'get',
        path: '/salary/calculation',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  favorites: {
    post: {
      type: 'post',
      path: '/jobs/favorites',
      isQuery: true,
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
    get: {
      type: 'get',
      path: '/jobs/favorites',
      isQuery: true,
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
    delete: {
      type: 'delete',
      path: '/jobs/favorites',
      isQuery: true,
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
    favoritepositionlist: {
      get: {
        type: 'get',
        path: '/jobs/favorites/positions/count',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
  },
  settings: {
    applications: {
      downloads: {
        get: {
          type: 'get',
          path: '/settings/applications/downloads',
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
      sms: {
        post: {
          type: 'post',
          path: '/settings/applications/sms',
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
    },
    adunits: {
      get: {
        type: 'get',
        path: '/settings/adunits/web',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  municipality: {
    get: {
      type: 'get',
      path: '/municipality',
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
  },
  companies: {
    detail: {
      get: {
        type: 'get',
        path: '/companies',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    evaluations: {
      score: {
        get: {
          type: 'get',
          path: '/companies/evaluations/avgscore',
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
      },
      survey: {
        get: {
          type: 'get',
          path: '/companies/evaluationsurveydetails',
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
        post: {
          type: 'post',
          path: '/companies/evaluationsurveys/answers',
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
      },
      checksurvey: {
        get: {
          type: 'get',
          path: '/companies/evaluations/list',
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
      },
    },
    job: {
      get: {
        type: 'get',
        path: '/companies/job',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
  },
  companyFollowing: {
    post: {
      type: 'post',
      path: '/candidates/followings',
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
    delete: {
      type: 'delete',
      path: '/candidates/followings',
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
  },
  job: {
    get: {
      type: 'get',
      path: '/jobs',
      // withCredentials: true,
      baseURL:
        process.env.NEXT_PUBLIC_WEB_JB_API_URL ||
        process.env.NEXT_PUBLIC_JB_API_URL,
    },
  },
  jobs: {
    totalcount: {
      alltimes: {
        get: {
          type: 'get',
          path: '/jobs/totalcount/alltimes',
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
    },
    count: {
      get: {
        type: 'get',
        path: '/jobs/count',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    increasecount: {
      put: {
        type: 'put',
        path: '/jobs/showcounts',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    jobsuggestion: {
      get: {
        type: 'get',
        path: '/jobs/suggestions',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    search: {
      get: {
        type: 'get',
        path: '/jobs/search',
        baseURL:
          process.env.NEXT_PUBLIC_WEB_JB_API_URL ||
          process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    jobDetailWeb: {
      get: (id) => ({
        type: 'get',
        path: `/jobs/${id}/web`,
        // withCredentials: true,
        baseURL:
          process.env.NEXT_PUBLIC_WEB_JB_API_URL ||
          process.env.NEXT_PUBLIC_JB_API_URL,
      }),
    },
    jobDetailWebV2: {
      get: {
        type: 'get',
        path: '/jobs/web',
        baseURL:
          process.env.NEXT_PUBLIC_WEB_JB_API_URL ||
          process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    applications: {
      post: {
        type: 'post',
        path: '/jobs/applications',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      get: {
        type: 'get',
        path: '/jobs/applications',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      temporary: {
        post: {
          type: 'post',
          path: '/jobs/applications/temporary',
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
        get: {
          type: 'get',
          path: '/jobs/applications/temporary',
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
        put: {
          type: 'put',
          path: '/jobs/applications/temporaries',
          isAuth: true,
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
      },
      maskingCall: {
        post: {
          type: 'post',
          path: '/phone-masking/call',
          isAuth: true,
          baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
        },
      },
    },
    multipleApplications: {
      get: {
        type: 'get',
        path: '/suggestions/for-candidate',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      post: {
        type: 'post',
        path: '/jobs/applications/recommendations',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    complaints: {
      get: {
        type: 'get',
        path: '/jobs/complaints',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      post: {
        type: 'post',
        path: '/jobs/complaints',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    bids: {
      get: {
        type: 'get',
        path: '/jobs/bids',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      post: {
        type: 'post',
        path: '/services/bids',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
  },
  account: {
    grantcodes: {
      post: {
        type: 'post',
        path: '/account/grantcodes',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
      put: {
        type: 'put',
        path: '/account/grantcodes',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  accounts: {
    delete: {
      type: 'delete',
      path: '/accounts',
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
    get: {
      type: 'get',
      path: '/accounts',
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
    suspended: {
      put: {
        type: 'put',
        path: '/accounts/suspended',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    status: {
      managements: {
        get: {
          type: 'get',
          path: '/accounts/status/managements',
          baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
        },
      },
    },
    confirmation: {
      delete: {
        type: 'delete',
        path: '/accounts/confirmations',
        isQuery: true,
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
      post: {
        type: 'post',
        path: '/accounts/confirmations',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    notifications: {
      post: {
        type: 'post',
        path: '/accounts/notifications',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    identitynumberinformation: {
      post: {
        type: 'post',
        path: '/accounts/identity-number-information/display',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  accountconfirmations: {
    get: {
      type: 'get',
      path: '/accountconfirmations/types',
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
    },
  },
  registrations: {
    post: {
      type: 'post',
      path: '/registrations',
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
    activations: {
      post: {
        type: 'post',
        path: '/registrations/activations',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    temporary: {
      post: {
        type: 'post',
        path: '/registrations/temporary',
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
  },
  candidates: {
    get: {
      type: 'get',
      path: '/candidates',
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
    put: {
      type: 'put',
      path: '/candidates',
      isAuth: true,
      baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
    },
    militarystatuses: {
      post: {
        type: 'post',
        path: '/candidates/militarystatuses',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      put: {
        type: 'put',
        path: '/candidates/militarystatuses',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    driverlicenses: {
      put: {
        type: 'put',
        path: '/candidates/driver-license',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    workingexperinces: {
      post: {
        type: 'post',
        path: '/candidates/workingexperinces',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      put: {
        type: 'put',
        path: '/candidates/workingexperinces',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      delete: {
        type: 'delete',
        path: '/candidates/workingexperinces',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    certificates: {
      post: {
        type: 'post',
        path: '/candidates/certificates',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      put: {
        type: 'put',
        path: '/candidates/certificates',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      delete: {
        type: 'delete',
        path: '/candidates/certificates',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    educations: {
      post: {
        type: 'post',
        path: '/candidates/educations',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      put: {
        type: 'put',
        path: '/candidates/educations',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    images: {
      put: {
        type: 'put',
        path: '/candidates/images',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    surveyservice: {
      get: {
        type: 'get',
        path: '/survey/service/interest',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      post: {
        type: 'post',
        path: '/survey/service/interest',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
    disasterinformations: {
      get: {
        type: 'get',
        path: '/candidates/disasters/surveys',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
      post: {
        type: 'post',
        path: '/candidates/disasters-informations',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_JB_API_URL,
      },
    },
  },
  agreements: {
    candidate: {
      get: {
        type: 'get',
        path: '/agreements/candidates/latest',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    candidatelatest: {
      get: {
        type: 'get',
        path: '/agreements/latest',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    candidatelatestapprove: {
      get: {
        type: 'get',
        path: '/agreements/latest/approve',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
      post: {
        type: 'post',
        path: '/agreements/latest/approve',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    userapplicationdatas: {
      get: {
        type: 'get',
        path: '/agreements/userapplicationform/latest',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    personaldatas: {
      get: {
        type: 'get',
        path: '/agreements/clarification/candidates/latest',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    personaldatasdialog: {
      get: {
        type: 'get',
        path: '/agreements/candidates/personaldatas/latest',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    companiesdatas: {
      get: {
        type: 'get',
        path: '/agreements/companies/personaldatas/latest',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    companiesAgreement: {
      get: {
        type: 'get',
        path: '/agreements/companies/latest',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    accounts: {
      get: {
        type: 'get',
        path: '/agreements/clarification/accounts',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
      post: {
        type: 'post',
        path: '/agreements/clarification/accounts',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    useraccounts: {
      get: {
        type: 'get',
        path: '/agreements/user/accounts',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
      post: {
        type: 'post',
        path: '/agreements/user/accounts',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  feedbacks: {
    accounts: {
      status: {
        managements: {
          get: {
            type: 'get',
            path: '/feedbacks/accounts/status/managements',
            isAuth: true,
            baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
          },
        },
      },
    },
    jobcomplaint: {
      get: {
        type: 'get',
        path: '/feedbacks/jobs/complaints',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  contents: {
    profanity: {
      post: {
        type: 'post',
        path: '/contents/profanity',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  military: {
    statuses: {
      get: {
        type: 'get',
        path: '/military/statuses',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  driver: {
    licenses: {
      get: {
        type: 'get',
        path: '/candidates/driver-licenses-type',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  education: {
    levels: {
      get: {
        type: 'get',
        path: '/educations/levels',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    statuses: {
      get: {
        type: 'get',
        path: '/educations/statuses',
        isAuth: true,
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  features: {
    temporaryApplication: {
      get: {
        type: 'get',
        path: '/features/temporary-application',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
    phoneMasking: {
      get: {
        type: 'get',
        path: '/FeaturePhoneMasking',
        baseURL: process.env.NEXT_PUBLIC_COMMON_API_URL,
      },
    },
  },
  ats: {
    registrations: {
      post: {
        type: 'post',
        path: '/registrations',
        isAts: true,
        baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
      },
    },
    registerActivations: {
      post: {
        type: 'post',
        path: '/registration/activations',
        isAts: true,
        baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
      },
    },
    companies: {
      membershipInformation: {
        get: {
          type: 'get',
          path: '/companies/membershipinformation',
          isAts: true,
          baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
        },
      },
      contact: {
        post: {
          type: 'post',
          path: '/companies/contact-us',
          isAts: true,
          baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
        },
      },
    },
    ecommerce: {
      products: {
        get: {
          type: 'get',
          path: '/ecommerce/products',
          isAts: true,
          baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
        },
      },
      orders: {
        post: {
          type: 'post',
          path: '/ecommerce/orders',
          isAts: true,
          baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
        },
      },
      billings: {
        taxOfficeCities: {
          get: {
            type: 'get',
            path: '/ecommerce/billings/taxofficecities',
            isAts: true,
            baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
          },
        },
        taxOffices: {
          get: {
            type: 'get',
            path: '/ecommerce/billings/taxoffices',
            isAts: true,
            baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
          },
        },
      },
    },
    jobs: {
      create: {
        post: {
          type: 'post',
          path: '/jobs',
          isAts: true,
          baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
        },
      },
    },
    serviceForm: {
      call: {
        post: {
          type: 'post',
          path: '/service-demand-form/call',
          isAts: true,
          baseURL: process.env.NEXT_PUBLIC_ATS_API_URL,
        },
      },
    },
  },
}
export default configs
